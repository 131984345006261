<template>
    <div class="py-4 container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card p-4">
                    <div class="card-header pb-0">
                        <h6>Contratos</h6>
                    </div>
                    <div class="p-4">
                        <div class="" id="element-to-print" v-if="contrato">
                            <p class="text-center font-weight-bold">CONTRATO DE COMPRAVENTA No. {{ contrato.codigo }} </p>
                            <p class="text-center font-weight-bold">Celebrado entre, <strong>CORPORATION VICE
                                    S.A.S.</strong> y {{ contrato.cliente }} </p>
                            <br>
                            <p class="text-justify">De una parte, la sociedad <strong>CORPORATION VICE S.A.S.
                                </strong>con<strong> NIT 901.214.358-9</strong> , domiciliada en la
                                ciudad de Yopal, quien para efectos de este contrato se denominará <strong>EL
                                    VENDEDOR</strong> , y, por otra
                                parte, el señor <strong>{{ contrato.cliente }}</strong>, identificado con cedula de
                                ciudadanía No. <strong>{{ contrato.cedula_nit }}</strong>. quien se
                                denominará <strong>EL COMPRADOR.</strong> Quienes conjuntamente se denominarán LAS
                                PARTES, han decidido
                                celebrar el presente contrato de compraventa, teniendo en cuenta:</p>
                            <p class="text-justify">Que <strong>CORPORATION VICE S.A.S. </strong>, en desarrollo de su
                                objeto social se dedica, entre otros, a la
                                fabricación, mantenimiento y distribución de implementos agrícolas, lo mismo que a la
                                importación de maquinaria agrícola.</p>
                            <p class="text-justify">Que <strong>EL VENDEDOR</strong>, por tratarse de una persona
                                jurídica, actúa por intermedio de su
                                Representante legal quien posee la facultad de adelantar todo lo referente a la parte
                                contractual relacionada con nuestro objeto social.</p>
                            <p class="text-justify">Que el señor <strong> {{ contrato.cliente }} </strong>, identificado
                                con cedula de ciudadanía No. <strong>{{ contrato.cedula_nit }}</strong>. es persona
                                natural, con capacidad para adquirir derechos y obligaciones y por lo tanto se
                                constituye en
                                <strong> COMPRADOR.</strong>
                            </p>
                            <p>En razón a lo anterior acuerdan las siguientes,</p>

                            <p class="text-center font-weight-bold">CLÁUSULAS</p>
                            <p class="text-justify"><strong>PRIMERA – OBJETO. El VENDEDOR </strong> vende <strong>AL
                                    COMPRADOR</strong> y éste compra el bien mueble, reconocido por
                                las partes como MAQUINARIAS AGRÍCOLAS la cual cuentan con las siguientes
                                características:
                            </p>
                            <p><strong>UNA (01) {{ contrato.maquina }}</strong></p>

                            <p class="text-justify"><strong>SEGUNDA – TÍTULO. </strong>Este bien fue adquirido por
                                <strong>EL VENDEDOR</strong> mediante importación ajustada a la
                                legislación colombiana. <strong>PARÁGRAFO</strong>. Como quiera que se trata de una
                                <strong>COMPRAVENTA</strong>, es claro el
                                no traspaso del derecho de dominio existente sobre las máquinas, derecho que permanece
                                en
                                poder del <strong>VENDEDOR</strong>, hasta la fecha en que se perfeccione la venta en
                                estricto sentido, esto
                                es a la fecha de cancelación del PRECIO total del bien mueble.</p>
                            <p class="text-justify"><strong>PARÁGRAFO PRIMERO – GARANTÍA.</strong> La garantía del
                                producto es un compromiso de calidad asumido
                                por <strong>CORPORATION VICE S.A.S.</strong> para los clientes y usuarios de sus
                                productos, con el fin de
                                garantizar el funcionamiento de los mismos y/o la sustitución de los elementos que
                                presenten
                                fallas de material o fabricación, durante un plazo determinado de un año ó 1.000, lo que
                                primero se cumpla.</p>

                            <p class="text-justify">Corresponde a <strong>CORPORATION VICE S.A.S.</strong> prestar
                                servicio de garantía, analizar y reemplazar los
                                artículos que son defectuosos, que hayan sido utilizados correctamente y que no hayan
                                sido
                                sometidos a un mantenimiento inadecuado. También es responsabilidad del distribuidor la
                                transmisión de los reclamos de garantía a los fabricantes. <strong>CORPORATION VICE
                                    S.A.S.</strong> cuenta
                                con un Comité de Garantía formada por profesionales capacitados, que analizan y aprueban
                                el
                                reembolso de las solicitudes de garantía presentadas para que los clientes no se vean
                                penalizados por fallas debido a problemas de fabricación y montaje de los componentes
                                que
                                forman parte de los productos. La información relativa a las garantías debe ser
                                almacenada y
                                procesada para ayudar a resolver los problemas que ocurrieron con mayor frecuencia en el
                                campo. Se deben seguir y respetar los lineamientos de las garantías de los productos
                                para
                                así garantizar su óptimo funcionamiento y desempeño, de no seguir los lineamientos
                                específicos y las instrucciones dadas a conocer por nuestro personal técnico capacitado
                                y
                                especializado <strong>EL COMPRADOR</strong> perdería la garantía del producto ya que
                                solo el personal
                                autorizado por <strong>CORPORATION VICE S.A.S.</strong> es el autorizado dentro del
                                término de la garantía.
                                <br>
                            </p>
                            <p>Ver anexo 01 manual de servicio.</p>

                            <!--TERCERO-->
                            <p class="text-justify"><strong>TERCERA – PRECIO. </strong>El precio de venta es de
                                <strong>ciento cincuenta millones quinientos mil pesos
                                    m/cte. (${{ contrato.monto }})</strong> siendo este el valor total de <strong>ESTE
                                    CONTRATO.</strong></p>
                            <p class="text-justify"><strong>PARÁGRAFO - FORMA DE PAGO.</strong> Las partes acuerdan que,
                                EL COMPRADOR pagará AL VENDEDOR de la
                                siguiente manera:</p>
                            <p class="text-justify"><strong>EL COMPRADOR </strong>Abonara el precio total pactado en la
                                tercera clausula en las cuentas corrientes
                                que <strong>El VENDEDOR </strong>mantiene en el sistema financiero.</p>
                            <p class="text-justify"><strong>CUARTA – PERFECCIONAMIENTO. </strong>Realizados los pagos
                                satisfactoriamente, las partes acuerdan
                                realizar el perfeccionamiento del contrato de <strong>COMPRAVENTA</strong> , esto es
                                realizar los respectivos
                                traspasos del derecho de dominio los cuales estarán a cargo del <strong>,COMPRADOR
                                    ,PARÁGRAFO. EL
                                    COMPRADOR </strong>entregará al <strong>VENDEDOR </strong>una suma de
                                <strong>quinientos mil pesos m/cte. ($500.000)</strong>
                                inicialmente, por concepto de trámites ante la secretaría de movilidad, dicho valor</p>
                            <p class="text-justify">comprende el registro de la maquinaria agrícola, quedando está en
                                primera instancia a nombre
                                de <strong>CORPORATION VICE S.A.S.</strong>, hasta el pago total del precio.</p>
                            <p class="text-justify"><strong>QUINTA – ENTREGA. </strong>El implemento agrícola tipo
                                cosechadora será entregado el día <strong>veinte (20)
                                    de julio de 2022 ( {{ contrato.fecha_entrega }})</strong>en la ciudad de<strong>
                                    …………………………. </strong>manera material y técnica, junto con la debida
                                capacitación a los operadores en conjunto y que consistirá en charla sobre mantenimiento
                                preventivo, funcionamiento y operaciones técnicas, de lo cual se levantarán las
                                respectivas
                                actas. PARÁGRAFO. la entrega de la maquinaria se hará teniendo en cuenta el inventario
                                disponible.</p>
                            <p class="text-justify"><strong>PARÁGRAFO PRIMERO. </strong>Las partes acuerdan que se
                                considera, como lugar del cumplimiento de la
                                obligación del pago del precio, por parte del <strong>COMPRADOR al VENDEDOR</strong>, en
                                la ciudad de Yopal.
                            </p>
                            <p class="text-justify"><strong>SEXTA – CLÁUSULA COMPROMISORIA. </strong>Las partes acuerdan
                                acudir a un tribunal de arbitramento,
                                compuesto por tres árbitros de la <strong> CAMARA DE COMERCIO de Yopal</strong>, quien
                                dirima el conflicto que
                                se pueda presentar ante-durante o después de la ejecución del objeto del contrato.
                                Excepto
                                el proceso ejecutivo, caso en el cual el <strong>VENDEDOR</strong>, acudirá directamente
                                a la Jurisdicción
                                ordinaria.</p>
                            <p class="text-justify"><strong>SEPTIMA – MÉRITO EJECUTIVO. </strong>Las partes acuerdan que
                                el presente documento presta mérito
                                ejecutivo, sobre la suma total de las obligaciones adquiridas en el mismo.</p>
                            <p class="text-justify"><strong>DÉCIMA – DEUDOR SOLIDARIO.</strong> Las partes acuerdan que
                                EL COMPRADOR podrá constituir deudor(es)
                                solidario(s), quien(es) aceptará(n) su condición consignando su firma al final del
                                contrato.
                            </p>
                            <p class="text-justify"><strong>DÉCIMA PRIMERA – CESIÓN. </strong>Podrá <strong>EL
                                    VENDEDOR</strong> ceder libremente los derechos que emanan de este
                                contrato y tal cesión producirá efectos respecto <strong>AL COMPRADOR</strong>, a partir
                                de la fecha de la
                                comunicación certificada en que a este se comunique.</p>
                            <p class="text-justify"><strong>DÉCIMA SEGUNDA</strong>- En cumplimiento de la Ley
                                Estatutaria 1581 de 2012, “Por la cual se dictan
                                disposiciones generales para la protección de datos personales”, y sus Decretos
                                reglamentarios 1377 de 2103, “Por el cual se reglamenta parcialmente la Ley 1581 de
                                2012”, y
                                el decreto 1074 de 2015" Por medio del cual se expide el Decreto Único Reglamentario del
                                Sector Comercio, Industria y Turismo, y demás normas concordantes”, se informa al
                                titular de
                                los datos que por medio de su conducta inequívoca, está autorizando de manera razonable,
                                la
                                autorización para el tratamiento de sus datos personales consignados en el presente
                                contrato
                                y de formularios que de este se deriven, de manera inequívoca, voluntaria, sin vicios de
                                consentimiento como dolo, error o intimidación, los cuales será incorporados en una base
                                de
                                datos de responsabilidad de CORPORATION VICE SAS, siendo tratados con la finalidad de:
                                la
                                gestión administrativa de la entidad así como la gestión de carácter comercial, contacto
                                y
                                envió de comunicaciones jurídicas, y comerciales sobre nuestros productos y/o servicios.
                                El
                                COMPRADOR podrá ejercer sus derechos de acceso, actualización, corrección, supresión,
                                revocación o reclamo por infracción sobre sus datos, mediante escrito dirigido a la
                                dirección de correo electrónico gerencia@corpovice.com, indicando en el Asunto, “el
                                derecho
                                que desea ejercer”; o mediante correo ordinario remitido a la dirección, Av. Marginal
                                7-126
                                de la ciudad de Yopal (Casanare). </p>
                            <p class="">El COMPRADOR declara haber leído la cláusula anterior y estar conforme con la
                                misma.</p>
                            <p class="text-center font-weight-bold">NOTIFICACIONES</p>
                            <p class="text-justify">Para todos los efectos de este contrato de compraventa, las
                                direcciones de notificación del
                                <strong>COMPRADOR y el VENDEDOR </strong>, serán las siguientes:
                            </p>
                            <p class="text-justify"><strong>DIRECCIÓN DEL VENDEDOR:</strong> Av. Marginal 7-126 de la
                                ciudad de Yopal, Departamento de Casanare.
                            </p>
                            <p class=""><strong>CORREO ELECTRÓNICO:</strong> ventas@corpovice.com</p>
                            <p class=""><strong>CEL:</strong> 323 282 1879</p>
                            <p class=""><strong>DIRECCIÓN DEL COMPRADOR:</strong> {{ contrato.direccioncliente }}.</p>
                            <p class=""><strong>CORREO ELECTRÓNICO:</strong> {{ contrato.correocliente }}.</p>
                            <p class=""><strong>CEL:</strong>{{ contrato.celularcliente }}</p>
                            <p class="">Para constancia el presente contrato se firma en …………..el día del mes de……….del
                                2024, en dos
                                ejemplares del mismo valor cada uno de ellos con destino a cada una de las Partes</p>
                            <p>Vendedor.</p>
                            <br>
                            <p>CORPORATION VICE S.A.S.</br>
                                NIT. 901.214.358-9</p>
                            <p>Comprador Codeudor</p>
                            <br><br><br>
                            <div class="row">
                                <div class="col-md-4">
                                    <p>Comprador</p><br><br><br>
                                    <p>……………………… …………………………</p>
                                </div>
                                <div class="col-md-4">
                                    <p>Codeudor</p><br><br><br>
                                    <p>……………………… …………………………</p>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="mt-4 row">
            <div class="col-12">
                <!-- projects-table component -->
            </div>
        </div>
        <button @click="generateReport" class="badge badge-sm bg-gradient-danger">PDF</button>
    </div>
</template>

<script>
import axios from "axios";
import html2pdf from "html2pdf.js";

export default {
    data() {
        return {
            contrato: null,
        };
    },
    mounted() {
        const contratoId = this.$route.params.idcontrato;
        console.log(contratoId);
        axios.get(`${this.$apiBaseUrl}/contratos/${contratoId}`)
            .then(response => {
                this.contrato = response.data.data;
            })
            .catch(error => {
                console.error('Error al obtener los detalles del contrato:', error);
            });
    },
    methods: {
        generateReport() {
            var element = document.getElementById('element-to-print');
            var opt = {
                margin: 0.7,
                filename: 'myfile.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' },
                header: function(currentPage, pageCount) {
            return `
                <div style="text-align: center;">Encabezado - Página ${currentPage} de ${pageCount}</div>
            `;
        },
        footer: function(currentPage, pageCount) {
            return `
                <div style="text-align: center; font-size: 10px;">Pie de página - Página ${currentPage} de ${pageCount}</div>
            `;
        }
                
            };


            // New Promise-based usage:
            html2pdf().from(element).set(opt).save();
        }
    }
};
</script>