import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import Tables from "../views/Tables.vue";
import Billing from "../views/Billing.vue";
import VirtualReality from "../views/VirtualReality.vue";
import RTL from "../views/Rtl.vue";
import Profile from "../views/Profile.vue";
import Signup from "../views/Signup.vue";
import Signin from "../views/Signin.vue";


//Clientes
import Clientes from "../views/clientes/Index.vue"; /*Clientes */
import ClientesForm from "../views/clientes/Form.vue"; /*Clientes Formulario*/
import ClientesEdit from "../views/clientes/Edit.vue"; /*Clientes Formulario*/

//Maquinas
import Maquinas from "../views/maquinas/Index.vue"; /*Maquinas */
import MaquinasForm from "../views/maquinas/Form.vue"; /*Maquinas */
import MaquinasView from "../views/maquinas/View.vue"; /*Maquinas */


//Proformas
import Proformas from "../views/proformas/Index.vue"; /*Proformas */
import ProformasForm from "../views/proformas/Form.vue"; /*Proformas */
import ProformasView from "../views/proformas/View.vue"; /*Proformas */
import Proformasdetalle from "../views/proformas/Vista.vue"; /*Contratos pdf */


//Contratos
import Contratos from "../views/contratos/Index.vue"; /*Contratos */
import ContratosForm from "../views/contratos/Form.vue"; /*Contratos */
import ContratosView from "../views/contratos/View.vue"; /*Contratos */
import Contratosdetalle from "../views/contratos/Vista.vue"; /*Contratos pdf */
const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/dashboard-default",
  },
  {
    path: "/dashboard-default",
    name: "Dashboard",
    component: Dashboard,
  },
  //CLIENTES
  {
    path: "/clientes",
    name: "Clientes",
    component: Clientes,
    meta: {
      title: "Clientes"
    }
  },
  {
    path: "/clientes/nuevo",
    name: "NuevoCliente",
    component: ClientesForm,
    meta: {
      title: "Nuevo Cliente"
    }
  },
  {
    path: "/clientes/:idcliente",
    name: "ClientesEdit",
    component: ClientesEdit,
    meta: {
      title: "Editar Cliente"
    }
  },
  //Maquinas
  {
    path: "/maquinas",
    name: "Maquinas",
    component: Maquinas,
  },
  {
    path: "/maquinas/nuevo",
    name: "MaquinasForm",
    component: MaquinasForm,
    meta: {
      title: "Nueva Maquina"
    }
  },
  {
    path: "/maquinas/:idmaquina",
    name: "MaquinasView",
    component: MaquinasView,
    meta: {
      title: "Editar Maquina"
    }
  },
  //Proformas
  {
    path: "/proformas",
    name: "Proformas",
    component: Proformas,
  },
  {
    path: "/proformas/nuevo",
    name: "ProformasForm",
    component: ProformasForm,
    meta: {
      title: "Nueva Proforma"
    }
  },
  {
    path: "/proformas/:idproforma",
    name: "ProformasView",
    component: ProformasView,
    meta: {
      title: "Editar Proforma"
    }
  },
  {
    path: "/proformaspdf/:idproforma",
    name: "Proformasdetalle",
    component: Proformasdetalle,
  },
  //Contratos
  {
    path: "/contratos",
    name: "Contratos",
    component: Contratos,
  },
  {
    path: "/contratos/nuevo",
    name: "ContratosForm",
    component: ContratosForm,
    meta: {
      title: "Nueva Contrato"
    }
  },
  {
    path: "/contratos/:idcontrato",
    name: "ContratosView",
    component: ContratosView,
    meta: {
      title: "Editar Contrato"
    }
  },
  {
    path: "/contratospdf/:idcontrato",
    name: "Contratosdetalle",
    component: Contratosdetalle,
  },
  {
    path: "/tables",
    name: "Tables",
    component: Tables,
  },

  {
    path: "/billing",
    name: "Billing",
    component: Billing,
  },
  {
    path: "/virtual-reality",
    name: "Virtual Reality",
    component: VirtualReality,
  },
  {
    path: "/rtl-page",
    name: "RTL",
    component: RTL,
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
  },
  {
    path: "/signin",
    name: "Signin",
    component: Signin,
  },
  {
    path: "/signup",
    name: "Signup",
    component: Signup,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

export default router;
