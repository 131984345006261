<script setup>
import MiniStatisticsCard from "@/examples/Cards/MiniStatisticsCard.vue";
import GradientLineChart from "@/examples/Charts/GradientLineChart.vue";
import Carousel from "./components/Carousel.vue";
import CategoriesList from "./components/CategoriesList.vue";

import US from "@/assets/img/icons/flags/US.png";
import DE from "@/assets/img/icons/flags/DE.png";
import GB from "@/assets/img/icons/flags/GB.png";
import BR from "@/assets/img/icons/flags/BR.png";

const sales = {
  us: {
    country: "United States",
    sales: 2500,
    value: "$230,900",
    bounce: "29.9%",
    flag: US,
  },
  germany: {
    country: "Germany",
    sales: "3.900",
    value: "$440,000",
    bounce: "40.22%",
    flag: DE,
  },
  britain: {
    country: "Great Britain",
    sales: "1.400",
    value: "$190,700",
    bounce: "23.44%",
    flag: GB,
  },
  brasil: {
    country: "Brasil",
    sales: "562",
    value: "$143,960",
    bounce: "32.14%",
    flag: BR,
  },
};
</script>
<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-lg-3 col-md-6 col-12">
            <mini-statistics-card title="Cantidad de Contratos" :value="contratos" description="<span
                class='text-sm font-weight-bolder text-success'
                >" :icon="{
                  component: 'ni ni-money-coins',
                  background: 'bg-gradient-primary',
                  shape: 'rounded-circle',
                }" />
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <mini-statistics-card title="Cantidad de Proformas" :value="proformas" description="<span
                class='text-sm font-weight-bolder text-success'
                >" :icon="{
                  component: 'ni ni-world',
                  background: 'bg-gradient-danger',
                  shape: 'rounded-circle',
                }" />
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <mini-statistics-card title="Cantidad de Clientes" :value="clientes" description="<span
                class='text-sm font-weight-bolder text-danger'
                >" :icon="{
                  component: 'ni ni-paper-diploma',
                  background: 'bg-gradient-success',
                  shape: 'rounded-circle',
                }" />
          </div>

        </div>
        <div class="row">
          <div class="col-lg-6 mb-lg">
            <!-- line chart -->
            <div class="card z-index-2">
              <gradient-line-chart id="chart-line" title="Cantidad de Contratos por Mes" :chart="{
                labels: [
                  'Apr',
                  'May',
                  'Jun',
                  'Jul',
                  'Aug',
                  'Sep',
                  'Oct',
                  'Nov',
                  'Dec',
                ],
                datasets: [
                  {
                    label: 'Mobile Apps',
                    data: [50, 40, 300, 220, 500, 250, 400, 230, 500],
                  },
                ],
              }" />
            </div>
          </div>
          <div class="col-lg-6 mb-lg">
            <!-- line chart -->
            <div class="card z-index-2">
              <gradient-line-chart id="chart-line2" title="Cantidad de proformas por Mes" :chart="{
                labels: [
                  'Apr',
                  'May',
                  'Jun',
                  'Jul',
                  'Aug',
                  'Sep',
                  'Oct',
                  'Nov',
                  'Dec',
                ],
                datasets: [
                  {
                    label: 'Mobile Apps',
                    data: [50, 40, 300, 220, 500, 250, 400, 230, 500],
                  },
                ],
              }" />
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-lg-12 mb-lg-0 mb-4">
            <div class="card">
              <div class="p-3 pb-0 card-header">
                <div class="d-flex justify-content-between">
                  <h6 class="mb-2">Últimos 10 Contratos</h6>
                </div>
              </div>
              <div class="table-responsive">

                <table class="table align-items-center">
                  <thead>
                    <tr>
                      <th class="text-secondary opacity-7">Código</th>
                      <th class="text-secondary opacity-7">NIT/Cédula</th>
                      <th class="text-secondary opacity-7">Cliente</th>
                      <th class="text-secondary opacity-7">Máquina</th>
                      <th class="text-secondary opacity-7">Monto</th>

                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="contrato in topcontratos" :key="contrato.id">
                      <td>{{ contrato.codigo }}</td>
                      <td>{{ contrato.cedula_nit }}</td>
                      <td>{{ contrato.cliente }}</td>
                      <td>{{ contrato.maquina }}</td>
                      <td>{{ contrato.monto }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import $ from 'jquery';
import Preloader from "@/components/Preloader.vue";
export default {
  components: {
    Preloader
  },
  data() {
    return {
      proformas: 0,
      contratos: 0,
      topcontratos: [],
      clientes: 0,
      dataTable: null,
      cargando: false
    };
  },
  mounted() {
    this.cantidadContratos();
    this.topContratos();
  },
  methods: {
    topContratos() {
      axios.get(`${this.$apiBaseUrl}/topcontratos`)
        .then((response) => {
          this.topcontratos = response.data.data;
        }).catch((error) => {
          console.error("Error al cargar ultimo id:", error);
        });
    },
    cantidadContratos() {
      axios.get(`${this.$apiBaseUrl}/cantidadcontratos`)
        .then((response) => {
          this.contratos = response.data.cantidadcontratos;
          this.cantidadClientes();
        }).catch((error) => {
          console.error("Error al cargar ultimo id:", error);
        });
    },
    cantidadClientes() {
      axios.get(`${this.$apiBaseUrl}/cantidadclientes`)
        .then((response) => {
          this.clientes = response.data.cantidadclientes;
          this.cantidadProformas();
        }).catch((error) => {
          console.error("Error al cargar ultimo id:", error);
        });
    },
    cantidadProformas() {
      axios.get(`${this.$apiBaseUrl}/cantidadproformas`)
        .then((response) => {
          this.proformas = response.data.cantidadproformas;
        }).catch((error) => {
          console.error("Error al cargar ultimo id:", error);
        });
    },

  },

};
</script>