<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card p-4">
          <div class="card-header pb-0">
            <h6>Maquinas</h6>
          </div>
          <div class="card-body px-0 pt-0 pb-2">
            <Preloader :visible="cargando" />
            <form @submit.prevent="submitForm" class="p-4">
              <div class="row">
                <div class="col-md-6">
                  <div class="mb-3">
                    <label for="nombre" class="form-label">Nombre:</label>
                    <input type="text" class="form-control" id="nombre" v-model="maquina.nombre" required>
                  </div>
                  <div class="mb-3">
                    <label for="marca" class="form-label">Marca:</label>
                    <input type="text" class="form-control" id="marca" v-model="maquina.marca">
                  </div>
                  <div class="mb-3">
                    <label for="modelo" class="form-label">Modelo:</label>
                    <input type="text" class="form-control" id="modelo" v-model="maquina.modelo">
                  </div>
                  <div class="mb-3">
                    <label for="descripcion" class="form-label">Descripción:</label>
                    <ckeditor :editor="editor" v-model="maquina.descripcion" :config="editorConfig"></ckeditor>
                  </div>
                  <div class="mb-3">
                    <label for="precio" class="form-label">Precio:</label>
                    <input type="number" class="form-control" id="precio" v-model="maquina.precio" required>
                  </div>
                  <div class="mb-3">
                    <label for="estado" class="form-label">Estado:</label>
                    <select class="form-select" id="estado" v-model="maquina.estado" required>
                      <option value="1">Activo</option>
                      <option value="0">Inactivo</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="mb-3">
                    <label for="imagen_uno" class="form-label">Imagen Uno:</label>
                    <input type="file" class="form-control" id="imagen_uno" @change="handleImagenUpload($event, 1)" >
                    <img v-if="maquina.ruta_imagen_uno" :src="`http://127.0.0.1:8000/storage/${maquina.ruta_imagen_uno}`" alt="Imagen Uno" style="max-width: 20%;">
                  </div>
                  <div class="mb-3">
                    <label for="imagen_dos" class="form-label">Imagen Dos:</label>
                    <input type="file" class="form-control" id="imagen_dos" @change="handleImagenUpload($event, 2)" >
                    <img v-if="maquina.ruta_imagen_dos" :src="`http://127.0.0.1:8000/storage/${maquina.ruta_imagen_dos}`" alt="Imagen Dos" style="max-width: 20%;">
                  </div>
                  <div class="mb-3">
                    <label for="imagen_tres" class="form-label">Imagen Tres:</label>
                    <input type="file" class="form-control" id="imagen_tres" @change="handleImagenUpload($event, 3)">
                    <img v-if="maquina.ruta_imagen_tres" :src="`http://127.0.0.1:8000/storage/${maquina.ruta_imagen_tres}`" alt="Imagen Tres" style="max-width: 20%;">
                  </div>
                  <div class="mb-3">
                    <label for="brochure" class="form-label">Brochure:</label>
                    <input type="file" class="form-control" id="brochure" @change="handleBrochureUpload" >
                    <a :href="`http://127.0.0.1:8000/storage/${maquina.ruta_brochure}`" target="_blank" class="btn btn-primary">Ver brochure</a>
                  </div>
                </div>
              </div>
              <button type="submit" class="btn btn-warning">Editar</button>
            </form>
            <div>
              <button @click="$router.push({ name: 'Maquinas' });" class="btn btn-primary">Regresar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Preloader from "@/components/Preloader.vue";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default {
  name: 'MaquinasView',
  components: {
    Preloader
  },
  data() {
    return {
      editor: ClassicEditor,
      editorData: '',
      editorConfig: {
        // The configuration of the editor.
      },
      cargando: false,
      maquina: {
        nombre: '',
        marca: '',
        modelo: '',
        descripcion: '',
        precio: null,
        estado: 1
      },
      imagenes: [],
      brochure: null
    };
  },
  mounted() {
    this.obtenerItem();
  },
  methods: {
    obtenerItem() {
      const maquinaID = this.$route.params.idmaquina;
      this.cargando = true;
      axios.get(`${this.$apiBaseUrl}/maquinas/${maquinaID}`)
        .then((response) => {
          this.maquina = response.data.data;
        }).catch((error) => {
          console.error("Error al obtener los datos de la máquina:", error);
        }).finally(() => {
          this.cargando = false;
        });
    },
    submitForm() {
      this.cargando = true;
      let formData = new FormData();
      formData.append('_method', 'put'); // Establecer el método como 'put'

      // Agregar los campos de la máquina al formData
      formData.append('nombre', this.maquina.nombre);
      formData.append('marca', this.maquina.marca);
      formData.append('modelo', this.maquina.modelo);
      formData.append('descripcion', this.maquina.descripcion);
      formData.append('precio', this.maquina.precio);
      formData.append('estado', this.maquina.estado);

      // Agregar las imágenes al formData
      this.imagenes.forEach((imagen, index) => {
          formData.append(`imagen_${index+1}`, imagen);
      });

      // Agregar el brochure al formData
      if (this.brochure) {
          formData.append('brochure', this.brochure);
      }

      // Realizar la solicitud axios con el método 'post'
      axios({
          method: "post", // Importante: utilizar el método 'post'
          url: `${this.$apiBaseUrl}/maquinas/${this.maquina.id}`,
          data: formData,
          headers: { "Content-Type": "multipart/form-data" }
      })
      .then((response) => {
          this.$swal('¡Máquina editada!', 'La máquina ha sido editada correctamente.', 'success');
          this.$router.push({ name: 'Maquinas' });
      })
      .catch((error) => {
          let errorMessage = "Hubo un problema al editar la máquina.";

          if (error.response && error.response.data && error.response.data.errors) {
              errorMessage = Object.values(error.response.data.errors).flat().join("\n");
          }

          this.$swal('Error', errorMessage, 'error');
          console.error("Error al editar la máquina:", error);
      })
      .finally(() => {
          this.cargando = false;
      });
    },
  handleImagenUpload(event, index) {
  // Verificar si event no es nulo o indefinido
  if (event && event.target && event.target.files) {
    // Acceder al archivo seleccionado
    const file = event.target.files[0];
    // Verificar si hay un archivo seleccionado
    if (file) {
      // Asignar la imagen al índice correspondiente en el array de imágenes
      this.imagenes[index - 1] = file;
    }
  }
},
    handleBrochureUpload(event) {
      // Verificar si event no es nulo o indefinido
      if (event && event.target && event.target.files) {
        // Asignar el brochure al objeto brochure
        this.brochure = event.target.files[0];
      }
    }
  }
};
</script>
