<template>
    <div class="py-4 container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card p-4">
                    <div class="card-header pb-0">
                        <h6>Editar Contrato</h6>
                    </div>
                    <div class="card-body px-0 pt-0 pb-2 ">
                        <Preloader :visible="cargando" />
                        <form @submit.prevent="submitForm" class="p-4">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="mb-3">
                                        <label for="codigo" class="form-label">Código:</label>
                                        <input type="text" class="form-control" id="codigo" v-model="contrato.codigo"
                                            required>
                                    </div>
                                    <div class="mb-3">
                                        <label for="proforma_id" class="form-label">Proforma(Máquina - Cliente -
                                            Código):</label>
                                        <v-select class="col-md-12" placeholder="Buscar proforma" label="resumen"
                                            :options="proformas" :reduce="proforma => proforma.id"
                                            v-model="contrato.proforma_id" disabled>
                                            <template v-slot:no-options>Proforma no encontrado</template>
                                        </v-select>
                                    </div>
                                   

                                    <div class="mb-3">
                                        <label for="fecha_contrato" class="form-label">Fecha de Contrato:</label>
                                        <input type="date" class="form-control" id="fecha_contrato"
                                            v-model="contrato.fecha_contrato" required>
                                    </div>
                                    <div class="mb-3">
                                        <label for="fecha_entrega" class="form-label">Fecha de Entrega:</label>
                                        <input type="date" class="form-control" id="fecha_entrega"
                                            v-model="contrato.fecha_entrega" required>
                                    </div>
                                    <div class="mb-3">
                                        <label for="fecha_registro" class="form-label">Fecha de Registro:</label>
                                        <input type="date" class="form-control" id="fecha_registro"
                                            v-model="contrato.fecha_registro" required>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="mb-3">
                                        <label for="uno" class="form-label">Cláusula PRIMERA:</label>
                                        <ckeditor :editor="editor" v-model="contrato.uno" :config="editorConfig">
                                        </ckeditor>
                                    </div>
                                    <div class="mb-3">
                                        <label for="dos" class="form-label">Cláusula SEGUNDA:</label>
                                        <ckeditor :editor="editor" v-model="contrato.dos" :config="editorConfig">
                                        </ckeditor>
                                    </div>
                                    <div class="mb-3">
                                        <label for="tres" class="form-label">Cláusula TERCERA:</label>
                                        <ckeditor :editor="editor" v-model="contrato.tres" :config="editorConfig">
                                        </ckeditor>
                                    </div>
                                    <div class="mb-3">
                                        <label for="cuatro" class="form-label">Cláusula CUARTA:</label>
                                        <ckeditor :editor="editor" v-model="contrato.cuatro" :config="editorConfig">
                                        </ckeditor>
                                    </div>
                                    <div class="mb-3">
                                        <label for="cinco" class="form-label">Cláusula QUINTA:</label>
                                        <ckeditor :editor="editor" v-model="contrato.cinco" :config="editorConfig">
                                        </ckeditor>
                                    </div>
                                    <div class="mb-3">
                                        <label for="seis" class="form-label">Cláusula SEXTA:</label>
                                        <ckeditor :editor="editor" v-model="contrato.seis" :config="editorConfig">
                                        </ckeditor>
                                    </div>
                                    <div class="mb-3">
                                        <label for="siete" class="form-label">Cláusula SÉPTIMA:</label>
                                        <ckeditor :editor="editor" v-model="contrato.siete" :config="editorConfig">
                                        </ckeditor>
                                    </div>
                                    <div class="mb-3">
                                        <label for="siete" class="form-label">Cláusula OCTAVA :</label>
                                        <ckeditor :editor="editor" v-model="contrato.ocho" :config="editorConfig">
                                        </ckeditor>
                                    </div>
                                    <div class="mb-3">
                                        <label for="siete" class="form-label">Cláusula NOVENA :</label>
                                        <ckeditor :editor="editor" v-model="contrato.nueve" :config="editorConfig">
                                        </ckeditor>
                                    </div>
                                    <div class="mb-3">
                                        <label for="siete" class="form-label">Cláusula DÉCIMA :</label>
                                        <ckeditor :editor="editor" v-model="contrato.diez" :config="editorConfig">
                                        </ckeditor>
                                    </div>
                                    <div class="mb-3">
                                        <label for="siete" class="form-label">Cláusula DÉCIMA PRIMERA:</label>
                                        <ckeditor :editor="editor" v-model="contrato.once" :config="editorConfig">
                                        </ckeditor>
                                    </div>
                                    <div class="mb-3">
                                        <label for="siete" class="form-label">Cláusula DÉCIMA SEGUNDA:</label>
                                        <ckeditor :editor="editor" v-model="contrato.doce" :config="editorConfig">
                                        </ckeditor>
                                    </div>
                                    <div class="mb-3">
                                        <label for="siete" class="form-label">Cláusula DÉCIMA TERCERA:</label>
                                        <ckeditor :editor="editor" v-model="contrato.trece" :config="editorConfig">
                                        </ckeditor>
                                    </div>

                                    <div class="mb-3">
                                        <label for="estado" class="form-label">Estado:</label>
                                        <select class="form-select" id="estado" v-model="contrato.estado" required>
                                            <option value="1">Activo</option>
                                            <option value="0">Inactivo</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <button type="submit" class="btn btn-primary">Guardar Cambios</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import Preloader from "@/components/Preloader.vue";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
    name: 'EditarContrato',
    components: {
        Preloader
    },
    data() {
        return {
            cargando: false,
            editor: ClassicEditor,
            editorConfig: {
                // Configuración del editor
            },
            contrato: {
                proforma_id: null,
                codigo: '',
                fecha_contrato: '',
                fecha_entrega: '',
                fecha_registro: '',
                uno: '',
                dos: '',
                tres: '',
                cuatro: '',
                cinco: '',
                seis: '',
                siete: '',
                ocho: '',
                nueve: '',
                diez: '',
                once: '',
                doce: '',
                trece: '',
                tipo: 1,
                estado: 1
            },
            tipoproforma: '',
            proformas: []
        };
    },
    mounted() {
        this.obtenerContrato();
        this.cargarProformas();
    },
    methods: {
        obtenerContrato() {
            const contratoId = this.$route.params.idcontrato;
            this.cargando = true;
            axios.get(`${this.$apiBaseUrl}/contratos/${contratoId}`)
                .then((response) => {

                    this.contrato = response.data.data;

                }).catch((error) => {
                    console.error("Error al obtener los datos del contrato:", error);
                }).finally(() => {
                    this.cargando = false;
                });
        },
        cargarProformas() {
            axios.get(`${this.$apiBaseUrl}/proformas`)
                .then((response) => {
                    this.proformas = response.data.data;
                }).catch((error) => {
                    console.error("Error al cargar proformas:", error);
                });
        },
        submitForm() {
            this.cargando = true;
            axios.put(`${this.$apiBaseUrl}/contratos/${this.contrato.id}`, this.contrato)
                .then((response) => {
                    this.$swal('¡Contrato editado!', 'El contrato ha sido editado correctamente.', 'success');
                    this.$router.push({ name: 'Contratos' });
                }).catch((error) => {
                    let errorMessage = "Hubo un problema al editar el contrato.";

                    if (error.response && error.response.data && error.response.data.errors) {
                        errorMessage = Object.values(error.response.data.errors).flat().join("\n");
                    }

                    this.$swal('Error', errorMessage, 'error');
                    console.error("Error al editar el contrato:", error);
                }).finally(() => {
                    this.cargando = false;
                });
        }
    }
};
</script>

<style scoped>
/* Estilos específicos del componente */
</style>
