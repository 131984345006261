import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import ArgonDashboard from "./argon-dashboard";

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';

import CKEditor from '@ckeditor/ckeditor5-vue';

import axios from 'axios';

//const API_BASE_URL = "http://127.0.0.1:8000/api/v1";
//https://apicorpvice.conit.lat/api/v1
const API_BASE_URL = "https://apicorpvice.conit.lat/api/v1";

const appInstance = createApp(App);
appInstance.use(store);
appInstance.use(router);
appInstance.use(ArgonDashboard);
appInstance.use(VueSweetalert2, { confirmButtonColor: '#F5A85B', cancelButtonColor: '#a5a5a5' });
appInstance.component('v-select', vSelect); 
appInstance.use(CKEditor);
appInstance.config.globalProperties.$apiBaseUrl = API_BASE_URL;
axios.defaults.baseURL = API_BASE_URL;

appInstance.mount("#app");
