<template>
    <div class="py-4 container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card p-4">
                    <div class="card-header pb-0">
                        <h6>Editar Proforma</h6>
                    </div>
                    <div class="card-body px-0 pt-0 pb-2 ">
                        <Preloader :visible="cargando" />
                        <form @submit.prevent="submitForm" class="p-4">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="mb-3">
                                        <label for="cliente_id" class="form-label">Cliente:</label>
                                        <v-select class="col-md-12" placeholder="Buscar cliente" label="nombre"
                                            :options="clientes" :reduce="cliente => cliente.id"
                                            v-model="proforma.cliente_id">
                                            <template v-slot:no-options>Cliente no encontrado</template>
                                        </v-select>
                                    </div>

                                    <div class="mb-3">
                                        <label for="producto_id" class="form-label">Producto:</label>
                                        <v-select class="col-md-12" placeholder="Buscar maquina" label="nombre"
                                            :options="productos" :reduce="producto => producto.id"
                                            v-model="proforma.producto_id">
                                            <template v-slot:no-options>Producto no encontrado</template>
                                        </v-select>
                                    </div>
                                    <div class="mb-3">
                                        <label for="forma_de_pago" class="form-label">Forma de Pago:</label>

                                        <select class="form-select" id="forma_de_pago" v-model="proforma.forma_de_pago"
                                            required>
                                            <option value="">Seleccionar forma de pago</option>
                                            <option value="Credito">Crédito</option>
                                            <option value="A cuota">A cuota</option>
                                            <option value="Contado">Contado</option>
                                        </select>
                                    </div>
                                    <div class="mb-3">
                                        <label for="codigo" class="form-label">Código:</label>
                                        <input type="text" class="form-control" id="codigo"
                                            v-model="proforma.codigo" required>
                                    </div>
                                 
                                </div>
                                <div class="col-md-6">
                                    <div class="mb-3">
                                        <label for="vigencia" class="form-label">Vigencia:</label>
                                        <input type="date" class="form-control" id="vigencia"
                                            v-model="proforma.vigencia" required>
                                    </div>
                                    <div class="mb-3">
                                        <label for="lugar_entrega" class="form-label">Lugar de Entrega:</label>
                                        <input type="text" class="form-control" id="lugar_entrega"
                                            v-model="proforma.lugar_entrega" required>
                                    </div>
                                    <div class="mb-3">
                                        <label for="monto" class="form-label">Monto:</label>
                                        <input type="number" class="form-control" id="monto" v-model="proforma.monto"
                                            required>
                                    </div>
                                    <div class="mb-3">
                                        <label for="estado" class="form-label">Estado:</label>
                                        <select class="form-select" id="estado" v-model="proforma.estado" required>
                                            <option value="1">Activo</option>
                                            <option value="0">Inactivo</option>
                                        </select>
                                    </div>
                                    <div class="mb-3">
                                        <label for="fecha_registro" class="form-label">Fecha de Registro:</label>
                                        <input type="datetime-local" class="form-control" id="fecha_registro"
                                            v-model="proforma.fecha_registro" required>
                                    </div>
                                </div>
                            </div>
                            <button type="submit" class="btn btn-warning">Editar</button>

                        </form>
                        <div>
                            <button @click="$router.push({ name: 'Proformas' });"
                                class="btn btn-primary">Regresar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import Preloader from "@/components/Preloader.vue";
export default {
    name: 'EditarProforma',
    components: {
        Preloader
    },
    data() {
        return {
            cargando: false,
            proforma: {
                id: null,
                producto_id: null,
                cliente_id: null,
                forma_de_pago: '',
                vigencia: '',
                lugar_entrega: '',
                monto: null,
                estado: 1,
                codigo: '',
                user_id: 1,
                fecha_registro: ''
            },
            clientes: [],
            productos: []
        };
    },
    mounted() {
        this.obtenerProforma();
        this.cargarClientes();
        this.cargarProductos();
    },
    methods: {
        obtenerProforma() {
            const proformaId = this.$route.params.idproforma;
            this.cargando = true;
            axios.get(`${this.$apiBaseUrl}/proformas/${proformaId}`)
                .then((response) => {
                    this.proforma = response.data.data;
                }).catch((error) => {
                    console.error("Error al obtener los datos de la proforma:", error);
                }).finally(() => {
                    this.cargando = false;
                });
        },
        cargarClientes() {
            axios.get(`${this.$apiBaseUrl}/clientes`)
                .then((response) => {
                    this.clientes = response.data.data;
                }).catch((error) => {
                    console.error("Error al cargar clientes:", error);
                });
        },
        cargarProductos() {
            axios.get(`${this.$apiBaseUrl}/maquinas`)
                .then((response) => {
                    this.productos = response.data.data;
                }).catch((error) => {
                    console.error("Error al cargar productos:", error);
                });
        },
        submitForm() {
            this.cargando = true;
            axios.put(`${this.$apiBaseUrl}/proformas/${this.proforma.id}`, this.proforma)
                .then((response) => {
                    this.$swal('¡Proforma editada!', 'La proforma ha sido editada correctamente.', 'success');
                    this.$router.push({ name: 'Proformas' });
                }).catch((error) => {
                    let errorMessage = "Hubo un problema al editar la proforma.";

                    if (error.response && error.response.data && error.response.data.errors) {
                        errorMessage = Object.values(error.response.data.errors).flat().join("\n");
                    }

                    this.$swal('Error', errorMessage, 'error');
                    console.error("Error al editar la proforma:", error);
                }).finally(() => {
                    this.cargando = false;
                });
        }
    }
};
</script>

<style scoped>
/* Estilos específicos del componente */
</style>
