<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header pb-0">
            <h6>Clientes</h6>
            <button class="btn btn-primary" @click="nuevoItem()">NUEVO</button>
          </div>
          <div class="card-body px-0 pt-0 pb-2">
            <Preloader :visible="cargando" /> <!-- Usar el componente del preloader -->
          </div>
          <div class="table-responsive p-4">
            <table class="table align-items-center mb-0 " ref="itemtabla">
              <thead>
                <tr class="align-middle text-center">
                  <th class="text-secondary opacity-7">Cedula</th>
                  <th class="text-secondary opacity-7">Nombres</th>
                  <th class="text-secondary opacity-7">Celular</th>
                  <th class="text-secondary opacity-7">Email</th>
                  <th class="text-secondary opacity-7">Opciones</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="cliente in clientes" :key="cliente.id" class="align-middle text-center">
                  <td>{{ cliente.cedula_nit }}</td>
                  <td>{{ cliente.nombre }}</td>
                  <td>{{ cliente.celular }}</td>
                  <td>{{ cliente.email }}</td>
                  <td>
                    <button @click="verCliente(cliente.id)" class="badge badge-sm bg-gradient-success">Ver</button>
                    <button @click="verCliente(cliente.id)" class="badge badge-sm bg-gradient-warning"><i
                        class="fas fa-pencil-alt text-dark me-2" aria-hidden="true"></i></button>
                    <button @click="eliminarCliente(cliente)" class="badge badge-sm bg-gradient-danger"><i
                        class="far fa-trash-alt me-2" aria-hidden="true"></i></button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import $ from 'jquery';
import Preloader from "@/components/Preloader.vue"; 
export default {
  components: {
    Preloader 
  },
  data() {
    return {
      clientes: [],
      dataTable: null,
      cargando: false 
    };
  },
  mounted() {
    this.listarClientes();
  },
  methods: {
    listarClientes() {
      this.cargando = true;
      axios.get(`${this.$apiBaseUrl}/clientes`)
        .then((response) => {
          this.clientes = response.data.data;
          this.initializeDataTable();
        }).catch((error) => {
          console.error("Error al obtener los datos de clientes:", error);
        }).finally(() => {
          this.cargando = false; 
        });
    },
    initializeDataTable() {
      this.$nextTick(() => {
        this.dataTable = $(this.$refs.itemtabla).DataTable();
      });
    },    
    verCliente(idcliente) {
      this.$router.push({ 
      name: 'ClientesEdit',
      params: { 
        idcliente: idcliente
      }
    });
    },
   
    nuevoItem(){
      this.$router.push({ name: 'NuevoCliente' }); 
    },
    eliminarCliente(cliente) {
     
      let objeto_id = cliente.id;
      this.$swal({
        title: '¿Estás seguro?',
        text: 'Esta acción cambiará el estado del cliente. ¿Quieres continuar?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sí, cambiar estado',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed) {
          this.cargando = true;
          axios.put(`${this.$apiBaseUrl}/clienteestado/` + objeto_id)
            .then((response) => {
              this.listarClientes();
              this.$swal('¡Estado cambiado!', 'El estado del cliente ha sido modificado.', 'success');
            })
            .catch((error) => {
              console.error("Error al cambiar el estado del cliente:", error);
              this.$swal('¡Error!', 'Ha ocurrido un error al cambiar el estado del cliente.', 'error');
            }).finally(() => {
              this.cargando = false; 
            });
        }
      });
    }
  }
};
</script>
