<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header pb-0">
            <h6>Proformas</h6>
            <button class="btn btn-primary" @click="nuevoItem()">NUEVO</button>
          </div>
          <div class="card-body px-0 pt-0 pb-2">
            <Preloader :visible="cargando" /> <!-- Usar el componente del preloader -->
          </div>
          <div class="table-responsive p-4">

            <table class="table align-items-center mb-0 table-responsive" ref="itemtabla">
              <thead>
                <tr class="align-middle text-center">
                  <th class="text-secondary opacity-7">#</th>
                  <th class="text-secondary opacity-7">NIT/Cédula</th>
                  <th class="text-secondary opacity-7">Cliente</th>
                  <th class="text-secondary opacity-7">Maquina</th>
                  <th class="text-secondary opacity-7">Monto</th>
                  <th class="text-secondary opacity-7">Opciones</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="proforma in proformas" :key="proforma.id" class="align-middle text-center">
                  <td>{{ proforma.id }}</td>
                  <td>{{ proforma.cedula_nit }}</td>
                  <td>{{ proforma.cliente }}</td>
                  <td>{{ proforma.maquina }}</td>
                  <td> $ {{ proforma.monto }}</td>
                  <td>
                    <!-- Botones de opciones -->
                    <button @click="descargarPdf(proforma.id)" class="badge badge-sm bg-gradient-danger">PDF</button>
                    <button @click="verProforma(proforma.id)" class="badge badge-sm bg-gradient-success">Ver</button>
                    <button @click="verProforma(proforma.id)" class="badge badge-sm bg-gradient-warning"><i
                        class="fas fa-pencil-alt text-dark me-2" aria-hidden="true"></i></button>
                    <button @click="eliminarProforma(proforma)" class="badge badge-sm bg-gradient-danger"><i
                        class="far fa-trash-alt me-2" aria-hidden="true"></i></button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

      </div>
    </div>
    <div class="mt-4 row">
      <div class="col-12">
        <projects-table />
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import $ from 'jquery';
import Preloader from "@/components/Preloader.vue";
export default {
  components: {
    Preloader
  },
  data() {
    return {
      proformas: [],
      dataTable: null,
      cargando: false
    };
  },
  mounted() {
    this.listarItems();
  },
  methods: {
    nuevoItem() {
      this.$router.push({ name: 'ProformasForm' });
    },
    eliminarProforma(proforma) {

      let objeto_id = proforma.id;
      this.$swal({
        title: '¿Estás seguro?',
        text: 'Esta acción cambiará el estado de la proforma. ¿Quieres continuar?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sí, cambiar estado',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed) {
          this.cargando = true;
          axios.put(`${this.$apiBaseUrl}/proformaestado/` + objeto_id)
            .then((response) => {
              this.listarItems();
              this.$swal('¡Estado cambiado!', 'El estado de la proforma ha sido modificado.', 'success');
            })
            .catch((error) => {
              console.error("Error al cambiar el estado de la proforma:", error);
              this.$swal('¡Error!', 'Ha ocurrido un error al cambiar el estado de la proforma.', 'error');
            }).finally(() => {
              this.cargando = false;
            });
        }
      });
    },
    descargarPdf(proformaid) {
      window.open("http://127.0.0.1:8000/proforma/pdfa4/" + proformaid + "," + "_blank");
    },
    listarItems() {
      this.cargando = true;
      axios.get(`${this.$apiBaseUrl}/proformas`).then((response) => {
        this.proformas = response.data.data;
        this.initializeDataTable();
      }).catch((error) => {
        console.error("Error al obtener los datos de proformas:", error);
      }).finally(() => {
        this.cargando = false;
      });
    },
    initializeDataTable() {
      this.$nextTick(() => {
        this.dataTable = $(this.$refs.itemtabla).DataTable();
      });
    },
    generateReport(id) {
      this.$router.push({ name: 'Proformasdetalle', params: { idproforma: id } });

    },
    verProforma(idproforma) {
      this.$router.push({
        name: 'ProformasView',
        params: {
          idproforma: idproforma
        }
      });
    },
  },

};
</script>