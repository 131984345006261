<template>
    <div class="py-4 container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card p-4">
                    <div class="card-header pb-0">
                        <h6>Proforma</h6>
                    </div>
                    <div class="p-4">
                        <div class="" id="element-to-print" v-if="proforma">
                            <p class="text-center font-weight-bold">PROFORMA N° {{ proforma.codigo }} </p>
                            <p>El Yopal, 17 febrero del 2021 </p>
                            <p class="font-weight-bold">Señor(a): {{ proforma.cliente }} <br>
                                C.C. : {{ proforma.cedula_nit }}<br>
                                Teléfono : {{ proforma.celularcliente }}<br>
                                Dirección : {{ proforma.direccioncliente }}<br>
                                Correo Electrónico : {{ proforma.correocliente }}<br>
                            </p>
                            <p class="text-justify">Por intermedio del presente nos dirigimos a usted, para hacerle
                                llegar su requerimiento de <strong>{{ proforma.maquina }}</strong> con las siguientes
                                Características:
                            </p>
                            <p>
                                MARCA:  LOVOL<br>
                                MODELO:  AF88G / 4LZ-5G<br>
                                MODALIDAD DE MANEJO:  HIDRÁULICA<br>
                                TIPO DE TRANSMISIÓN:  HIDRÁULICA - HST / VARIABLE CONTINUA<br>
                                MEDIDA DE ORUGA:  500X90X56<br>
                                ANCHO DE CORTE:  2.20 METROS APROXIMADAMENTE<br>
                                ANCHO DE GARGANTA:  55 CENTÍMETROS<br>
                                ALTURA DE CABEZAL:  90 CENTÍMETROS APROX. (DESDE EL PISO)<br>
                                ZARANDA:  1.8 METROS<br>
                                TIPOS DE TRILLA:  CONVENCIONAL Y RECUPERADOR DE GRANO<br>
                                CILINDRO DE TRILLA:  2.00 METROS<br>
                                PESO NETO:  2,970 KG<br>
                                CAPACIDAD DE TRABAJO:  3.0 A 4.5 HECTÁREAS POR DÍA APROX.<br>
                                CAPACIDAD DE TOLVA:  800 KG APROXIMADAMENTE<br>
                                TUBO DE DESCARGA:  AUTOMÁTICO CON BOTELLA HIDRÁULICA<br>
                                MOTOR:  3.0 A 4.5 HECTÁREAS POR DÍA APROX.<br>
                                CAPACIDAD DE TRABAJO:  DACHAI - DEUTZ CA498Z-10T2 / 74 KW CON TURBO<br>
                                COMBUSTIBLE:  DIESEL<br>
                                AÑO:  2020<br>
                                SERIE DE MOTOR:  DATOS AL MOMENTO DEL CIERRE DE LA VENTA                                <br>
                                SERIE DE CHASIS:  DATOS AL MOMENTO DEL CIERRE DE LA VENTA<br>
                            </p>
                            <p><strong>Cantidad : 1</strong></p>
                            <p>Precio {{ proforma.maquina }}: <strong>$ {{ proforma.monto }} </strong>Pesos Colombianos 
                            </p>
                            <p>
                                <strong>Total</strong>: $ {{ proforma.monto }} <br>
                                <strong>Forma de Pago </strong>: {{ proforma.forma_de_pago }} <br>
                                <strong>Vigencia Proforma</strong>:  {{ proforma.vigencia }} <br>
                                <strong>Lugar de Entrega </strong>:  {{ proforma.lugar_entrega }} <br>
                            </p>
                            <p class="text-justify">Cualquier información adicional, comuníquese con el suscrito, o al correo carlosbernal53@hotmail.com  y ventascorpvice@gmail.com

                            </p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="mt-4 row">
            <div class="col-12">
                <!-- projects-table component -->
            </div>
        </div>
        <button @click="generateReport" class="badge badge-sm bg-gradient-danger">PDF</button>
    </div>
</template>

<script>
import axios from "axios";
import html2pdf from "html2pdf.js";

export default {
    data() {
        return {
            proforma: null,
        };
    },
    mounted() {
        const proformaId = this.$route.params.idproforma;
        console.log(proformaId);
        axios.get(`${this.$apiBaseUrl}/proformas/${proformaId}`)
            .then(response => {
                this.proforma = response.data.data;
            })
            .catch(error => {
                console.error('Error al obtener los detalles de proforma:', error);
            });
    },
    methods: {
        generateReport() {
            var element = document.getElementById('element-to-print');
            var opt = {
                margin: 0.7,
                filename: 'myfile.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' },
                header: function (currentPage, pageCount) {
                    return `
                <div style="text-align: center;">Encabezado - Página ${currentPage} de ${pageCount}</div>
            `;
                },
                footer: function (currentPage, pageCount) {
                    return `
                <div style="text-align: center; font-size: 10px;">Pie de página - Página ${currentPage} de ${pageCount}</div>
            `;
                }

            };


            // New Promise-based usage:
            html2pdf().from(element).set(opt).save();
        }
    }
};
</script>