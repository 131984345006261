<template>
    <div class="py-4 container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card p-4">
                    <div class="card-header pb-0">
                        <h6>Clientes</h6>                     
                    </div>
                    <div class="card-body px-0 pt-0 pb-2 ">
                        <Preloader :visible="cargando" /> <!-- Usar el componente del preloader -->
                        <form @submit.prevent="submitForm" class="p-4">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="mb-3">
                                        <label for="cedula_nit" class="form-label">Cédula/NIT:</label>
                                        <input type="text" class="form-control" id="cedula_nit"
                                            v-model="cliente.cedula_nit" required>
                                    </div>
                                    <div class="mb-3">
                                        <label for="nombre" class="form-label">Nombre:</label>
                                        <input type="text" class="form-control" id="nombre" v-model="cliente.nombre"
                                            required>
                                    </div>
                                    <div class="mb-3">
                                        <label for="tipo_cliente" class="form-label">Tipo de Cliente:</label>
                                        <select class="form-select" id="es_empresa" v-model="cliente.es_empresa"
                                            required>
                                            <option value="0">Persona Natural</option>
                                            <option value="1">Empresa</option>
                                        </select>
                                    </div>
                                    <div v-if="cliente.es_empresa" class="mb-3">
                                        <label for="nombre_empresa" class="form-label">Nombre Empresa:</label>
                                        <input type="text" class="form-control" id="nombre_empresa"
                                            v-model="cliente.nombre_empresa" :required="cliente.es_empresa == 1"
                                            :disabled="cliente.es_empresa == 0">
                                    </div>
                                    <div class="mb-3">
                                        <label for="celular" class="form-label">Celular:</label>
                                        <input type="text" class="form-control" id="celular" v-model="cliente.celular"
                                            required>
                                    </div>
                                    <div class="mb-3">
                                        <label for="email" class="form-label">Email:</label>
                                        <input type="email" class="form-control" id="email" v-model="cliente.email"
                                            required>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="mb-3">
                                        <label for="departamento" class="form-label">Departamento:</label>
                                        <input type="text" class="form-control" id="departamento"
                                            v-model="cliente.departamento" required>
                                    </div>
                                    <div class="mb-3">
                                        <label for="ciudad" class="form-label">Ciudad:</label>
                                        <input type="text" class="form-control" id="ciudad" v-model="cliente.ciudad"
                                            required>
                                    </div>
                                    <div class="mb-3">
                                        <label for="direccion" class="form-label">Dirección:</label>
                                        <input type="text" class="form-control" id="direccion"
                                            v-model="cliente.direccion" required>
                                    </div>
                                    <!--ESTADO-->
                                    <div class="mb-3">
                                        <label for="estado" class="form-label">Estado:</label>
                                        <select class="form-select" id="estado" v-model="cliente.estado" required>
                                            <option value="1">Activo</option>
                                            <option value="0">Inactivo</option>
                                        </select>
                                    </div>
                                    <!--ESTADO-->
                                </div>
                            </div>
                            <button type="submit" class="btn btn-primary">Enviar</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import $ from 'jquery';
import Preloader from "@/components/Preloader.vue";
export default {
    name: 'NuevoCliente',
    components: {
        Preloader
    },
    data() {
        return {
            clientes: [],
            dataTable: null,
            cargando: false,
            cliente: {
                cedula_nit: '',
                nombre: '',
                es_empresa: 1,
                nombre_empresa: '',
                celular: '',
                email: '',
                departamento: '',
                ciudad: '',
                direccion: '',
                estado: 1
            }
        };
    },
    mounted() {
        // this.listarClientes();
    },
    methods: {
        submitForm() {
            this.cargando = true;
            axios.post(`${this.$apiBaseUrl}/clientes`, this.cliente)
                .then((response) => {
                    this.$swal('¡Cliente insertado!', 'El nuevo cliente ha sido añadido correctamente.', 'success');
                    this.$router.push({ name: 'Clientes' });
                }).catch((error) => {
                    let errorMessage = "Hubo un problema al insertar el cliente.";

                    if (error.response && error.response.data && error.response.data.errors) {
                        // Concatenar los mensajes de error de todos los campos
                        errorMessage = Object.values(error.response.data.errors).flat().join("\n");
                    }

                    this.$swal('Error', errorMessage, 'error');
                    console.error("Error al insertar el cliente:", error);
                }).finally(() => {
                    this.cargando = false;
                });
        }
    }
};
</script>