<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card p-4">
          <div class="card-header pb-0">
            <h6>Insertar Máquina</h6>
          </div>
          <div class="card-body px-0 pt-0 pb-2">
            <Preloader :visible="cargando" />
            <form @submit.prevent="submitForm" class="p-4" enctype="multipart/form-data">
              <div class="row">
                <div class="col-md-6">
                  <div class="mb-3">
                    <label for="nombre" class="form-label">Nombre:</label>
                    <input type="text" class="form-control" id="nombre" v-model="maquina.nombre" required>
                  </div>
                  <div class="mb-3">
                    <label for="marca" class="form-label">Marca:</label>
                    <input type="text" class="form-control" id="marca" v-model="maquina.marca">
                  </div>
                  <div class="mb-3">
                    <label for="modelo" class="form-label">Modelo:</label>
                    <input type="text" class="form-control" id="modelo" v-model="maquina.modelo">
                  </div>
                  <div class="mb-3">
                    <label for="descripcion" class="form-label">Descripción:</label>
                    <ckeditor :editor="editor" v-model="maquina.descripcion" :config="editorConfig"></ckeditor>
                  </div>
                  <div class="mb-3">
                    <label for="precio" class="form-label">Precio:</label>
                    <input type="number" class="form-control" id="precio" v-model="maquina.precio" required>
                  </div>
                  <div class="mb-3">
                    <label for="estado" class="form-label">Estado:</label>
                    <select class="form-select" id="estado" v-model="maquina.estado" required>
                      <option value="1">Activo</option>
                      <option value="0">Inactivo</option>
                    </select>
                  </div>

                </div>
                <div class="col-md-6">

                  <div class="mb-3">
                    <label for="imagen_uno" class="form-label">Imagen Uno:</label>
                    <input type="file" class="form-control" id="imagen_uno" @change="handleImagenUpload(1, $event)"
                      required>
                  </div>
                  <div class="mb-3">
                    <label for="imagen_dos" class="form-label">Imagen Dos:</label>
                    <input type="file" class="form-control" id="imagen_dos" @change="handleImagenUpload(2, $event)"
                      required>
                  </div>
                  <div class="mb-3">
                    <label for="imagen_tres" class="form-label">Imagen Tres:</label>
                    <input type="file" class="form-control" id="imagen_tres" @change="handleImagenUpload(3, $event)"
                      required>
                  </div>
                  <div class="mb-3">
                    <label for="brochure" class="form-label">Brochure:</label>
                    <input type="file" class="form-control" id="brochure" @change="handleBrochureUpload($event)"
                      required>
                  </div>
                </div>
              </div>
              <button type="submit" class="btn btn-primary">Enviar</button>

            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Preloader from "@/components/Preloader.vue";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default {
  name: 'NuevaMaquina',
  components: {
    Preloader
  },
  data() {
    return {
      editor: ClassicEditor,
      editorData: '',
      editorConfig: {
        // The configuration of the editor.
      },
      maquina: {
        nombre: '',
        marca: '',
        modelo: '',
        descripcion: '',
        precio: null,
        estado: 1
      },
      cargando: false,
      imagenes: [],
      brochure: null
    };
  },
  methods: {
    submitForm() {
      this.cargando = true;
      let formData = new FormData();
      formData.append('nombre', this.maquina.nombre);
      formData.append('marca', this.maquina.marca);
      formData.append('modelo', this.maquina.modelo);
      formData.append('descripcion', this.maquina.descripcion);
      formData.append('precio', this.maquina.precio);
      formData.append('estado', this.maquina.estado);
      this.imagenes.forEach((imagen, index) => {
        formData.append(`imagen_${index + 1}`, imagen);
      });
      formData.append('brochure', this.brochure);

      axios.post(`${this.$apiBaseUrl}/maquinas`, formData)
        .then((response) => {
          this.$swal('¡Máquina insertada!', 'La nueva máquina ha sido añadida correctamente.', 'success');
          this.$router.push({ name: 'Maquinas' });
        }).catch((error) => {
          let errorMessage = "Hubo un problema al insertar la máquina.";
          if (error.response && error.response.data && error.response.data.errors) {
            errorMessage = Object.values(error.response.data.errors).flat().join("\n");
          }
          this.$swal('Error', errorMessage, 'error');
          console.error("Error al insertar la máquina:", error);
        }).finally(() => {
          this.cargando = false;
        });
    },
    handleImagenUpload(index, event) {
      // Asigna la imagen al array de imágenes en el índice correcto
      this.imagenes[index - 1] = event.target.files[0];
    },
    handleBrochureUpload(event) {
      this.brochure = event.target.files[0];
    }
  }
};
</script>

<style scoped>
/* Estilos específicos para este componente si es necesario */
</style>