
<template>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
              <div class="card-header pb-0">
                  <h6>Máquinas</h6>
                  <button class="btn btn-primary" @click="nuevoItem()">NUEVO</button>
              </div>
              <div class="card-body px-0 pt-0 pb-2">
                <Preloader :visible="cargando" /> <!-- Usar el componente del preloader -->
              </div>
              <div class="table-responsive p-4">
            
                  <table class="table align-items-center mb-0" ref="itemtabla">
                      <thead>
                          <tr class="align-middle text-center">                        
                            <th class="text-secondary opacity-7">#</th>
                            <th class="text-secondary opacity-7">Nombres</th>
                            <th class="text-secondary opacity-7">Marca</th>
                            <th class="text-secondary opacity-7">Modelo</th>
                            <th class="text-secondary opacity-7">Opciones</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="maquina in maquinas" :key="maquina.id" class="align-middle text-center">
                              <td >{{ maquina.id }}</td>
                              <td >{{ maquina.nombre }}</td>
                              <td >{{ maquina.marca }}</td>
                              <td >{{ maquina.modelo }}</td>
                              <td >
                                  <!-- Botones de opciones -->
                                  <button @click="verMaquina(maquina.id)" class="badge badge-sm bg-gradient-success">Ver</button>
                                  <button @click="verMaquina(maquina.id)" class="badge badge-sm bg-gradient-warning"><i class="fas fa-pencil-alt text-dark me-2" aria-hidden="true"></i></button>
                                  <button @click="eliminarMaquina(maquina)" class="badge badge-sm bg-gradient-danger"><i class="far fa-trash-alt me-2" aria-hidden="true"></i></button>
                                </td>
                          </tr>
                        </tbody>
                  </table>
              </div>
          </div>
         
        </div>
      </div>
      <div class="mt-4 row">
        <div class="col-12">
          <projects-table />
        </div>
      </div>
    </div>
  </template>
  <script>
  import axios from "axios";
  import $ from 'jquery';
import Preloader from "@/components/Preloader.vue";
  export default {
    components: {
    Preloader
  },
      data() {
      return {
        maquinas: [],     
        dataTable: null,
      cargando: false  
      };
    },
    mounted() {
    this.listarItems();
  },
  methods: {
    listarItems() {
      this.cargando = true;
      axios.get(`${this.$apiBaseUrl}/maquinas`).then((response) => {
        this.maquinas=[];
        this.maquinas = response.data.data;
        this.initializeDataTable();
      }).catch((error) => {
        console.error("Error al obtener los datos de maquinas:", error);
      }).finally(() => {
        this.cargando = false;
      });
    },
    initializeDataTable() {
      this.$nextTick(() => {
        this.dataTable = $(this.$refs.itemtabla).DataTable();
      });
    },
    nuevoItem(){
      this.$router.push({ name: 'MaquinasForm' }); 
    },
    verMaquina(idmaquina) {
      this.$router.push({ 
      name: 'MaquinasView',
      params: { 
        idmaquina: idmaquina
      }
    });
    },
   
    eliminarMaquina(maquina) {
     
      let objeto_id = maquina.id;
      this.$swal({
        title: '¿Estás seguro?',
        text: 'Esta acción cambiará el estado de la máquina. ¿Quieres continuar?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sí, cambiar estado',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed) {
          this.cargando = true;
          axios.put(`${this.$apiBaseUrl}/maquinaestado/`+ objeto_id)
            .then((response) => {
              this.listarItems();
             //this.dataTable.ajax.reload(); 
              this.$swal('¡Estado cambiado!', 'El estado del maquina ha sido modificado.', 'success');
            })
            .catch((error) => {
              console.error("Error al cambiar el estado de la máquina:", error);
              this.$swal('¡Error!', 'Ha ocurrido un error al cambiar el estado de la maquina.', 'error');
            }).finally(() => {
              this.cargando = false; 
            });
        }
      });
    }
    

  }
  };
  </script>